var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Verify", {
    ref: "verify",
    attrs: {
      "captcha-type": "blockPuzzle",
      "img-size": { width: "330px", height: "155px" },
      mode: "pop",
    },
    on: { success: _vm.verifySuccessHandle },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }