import { render, staticRenderFns } from "./AppMain.vue?vue&type=template&id=078753dd&scoped=true&"
import script from "./AppMain.vue?vue&type=script&lang=js&"
export * from "./AppMain.vue?vue&type=script&lang=js&"
import style0 from "./AppMain.vue?vue&type=style&index=0&id=078753dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_vue-template-compiler@2.6.12_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "078753dd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\work\\baby-health-back-front\\node_modules\\.pnpm\\vue-hot-reload-api@2.3.4\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('078753dd')) {
      api.createRecord('078753dd', component.options)
    } else {
      api.reload('078753dd', component.options)
    }
    module.hot.accept("./AppMain.vue?vue&type=template&id=078753dd&scoped=true&", function () {
      api.rerender('078753dd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/AppMain.vue"
export default component.exports