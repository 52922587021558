import { getToken } from '@/utils/auth'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from './router'
import store from './store'
NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
    NProgress.start()
	var url=window.location.pathname;
	var aa=url.split('/')
	if(aa[2]=='s'){
		 next()
	}else if(aa[2]=='edit'){
		 next()
	}else{
		if (getToken()) {
	        to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
	        /* has token*/
	        if (to.path === '/login') {
	            next({ path: '/' })
	            NProgress.done()
	        } else {
	            if (store.getters.groups.length === 0) {
	                // 判断当前用户是否已拉取完user_info信息
	                store
	                    .dispatch('GetInfo')
	                    .then(() => {
	                        store.dispatch('GenerateRoutes').then(accessRoutes => {
	                            // 根据roles权限生成可访问的路由表
	                            router.addRoutes(accessRoutes) // 动态添加可访问路由表
	                            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
	                        })
	                    })
	                    .catch(err => {
	                        store.dispatch('LogOut').then(() => {
	                            console.error(err)
	                            next({ path: '/' })
	                        })
	                    })
	            } else {
	                next()
	            }
	        }
	    } else {
	        // 没有token
	        if (whiteList.indexOf(to.path) !== -1) {
	            // 在免登录白名单，直接进入
	            next()
	        } else {
	            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
	            NProgress.done()
	        }
	    }
	}
    
})

router.afterEach(() => {
    NProgress.done()
})
