var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: {
            disabled: _vm.disabled,
            multiple: _vm.multiple,
            filterable: "",
            remote: "",
            placeholder: _vm.placeholder,
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading,
            clearable: "",
          },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.select_value,
            callback: function ($$v) {
              _vm.select_value = $$v
            },
            expression: "select_value",
          },
        },
        [
          _vm._l(_vm.selectPageOptions, function (item) {
            return _c("el-option", {
              key: item[_vm.show_id],
              attrs: {
                label: item.admin_id ? item.nickname : item[_vm.show_field],
                value: item[_vm.show_id],
              },
            })
          }),
          _c(
            "div",
            { staticClass: "selectPage" },
            [
              _c(
                "el-link",
                {
                  staticClass: "mr10",
                  attrs: { underline: false, disabled: _vm.page === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.moreOriginPage("preview")
                    },
                  },
                },
                [_vm._v("上一页")]
              ),
              _c(
                "el-link",
                {
                  staticClass: "mr10",
                  attrs: { underline: false, disabled: !_vm.has_next },
                  on: {
                    click: function ($event) {
                      return _vm.moreOriginPage("next")
                    },
                  },
                },
                [_vm._v("下一页")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }