<template>
    <div>
        <template v-if="value">
            <!-- <el-image class="curd-image" v-for="(item, index) in value.split(',')" :key="index" :src="item" fit="cover" :preview-src-list="[item]"></el-image> -->
            <!-- <video ref="videoPlayer" class="video-js"></video> -->
            
            <el-link type="primary" :underline="false" @click="videoShow=true">音乐播放</el-link>
        </template>
        <el-dialog title="音乐播放" :visible.sync="videoShow"
                   class="dialog-no-pd"
                   width="30%" destroy-on-close append-to-body :before-close='dialogClose'>
            <div >
                <audio controls ref="audio" class="aud">
                    <source :src="value" />
                </audio>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'QuickAdminAudio',
    props: {
        value: {
            required: true,
            type: String | null,
        },
    },
    methods:{
        dialogClose(){ 
            this.$refs.audio.pause() 
            this.videoShow=false
        }
    },
    data(){
        return{
            videoShow:false
        }
    }
}
</script>
<style>
.curd-video{
    width: 100%;
}
</style>