var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formConfig.formKey
    ? _c("biz-project-form", {
        attrs: { "form-config": _vm.formConfig },
        on: { submit: _vm.submitForm },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }