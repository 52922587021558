import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2975dbf8&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.2_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_vue-template-compiler@2.6.12_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\work\\baby-health-back-front\\node_modules\\.pnpm\\vue-hot-reload-api@2.3.4\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2975dbf8')) {
      api.createRecord('2975dbf8', component.options)
    } else {
      api.reload('2975dbf8', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=2975dbf8&", function () {
      api.rerender('2975dbf8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QuickAdmin/Git/index.vue"
export default component.exports