<template>
    <div>
        <template v-if="value">
            <div v-for="(item, index) in value.split(',')" :key="index">
               <el-image class="curd-image"  :src="item" fit="cover" :preview-src-list="[item]" v-if="index==0"></el-image>
            </div>

        </template>
    </div>
</template>

<script>
export default {
    name: 'QuickAdminImage',
    props: {
        value: {
            required: true,
            type: String | null,
        },
    },
}
</script>
