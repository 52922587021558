var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "提示",
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("p", { staticStyle: { "text-align": "center", color: "#a1a1a1" } }, [
        _vm._v(
          _vm._s(_vm.percentage >= 100 ? _vm.completeMessage : _vm.message)
        ),
      ]),
      _c("el-progress", { attrs: { percentage: _vm.percentage } }),
      _vm.downloadUrl
        ? _c("el-link", { attrs: { href: _vm.downloadUrl, type: "primary" } }, [
            _vm._v(" 下载地址 "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }